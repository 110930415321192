<div class="card">
    <div class="card-body">
        <h5 class="card-title fw-semibold mb-4">Documentos</h5>
        <div class="row">
            <div class="col-lg-6">
                <mat-form-field class="pe-2" >
                    <mat-label>Buscar desde</mat-label>
                    <input matInput [matDatepicker]="fec_desde">
                    <mat-datepicker-toggle matIconSuffix [for]="fec_desde"></mat-datepicker-toggle>
                    <mat-datepicker #fec_desde></mat-datepicker>
                </mat-form-field>
                <mat-form-field>
                    <mat-label>Buscar hasta</mat-label>
                    <input matInput [matDatepicker]="fec_hasta">
                    <mat-datepicker-toggle matIconSuffix [for]="fec_hasta"></mat-datepicker-toggle>
                    <mat-datepicker #fec_hasta></mat-datepicker>
                </mat-form-field>
            </div>
            <div align="end" class="col-lg-6">
                <button mat-raised-button>Descargar selección</button>
            </div>
        </div>
        <div class="mat-elevation-z8">
            <table mat-table [dataSource]="dataSource" matSort>
          
              <!-- ID Column -->
              <ng-container matColumnDef="folio">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Folio </th>
                <td mat-cell *matCellDef="let row"> {{row.folio}} </td>
              </ng-container>
          
              <!-- Progress Column -->
              <ng-container matColumnDef="fecha">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Fecha </th>
                <td mat-cell *matCellDef="let row"> {{row.fecha | date:'dd/MM/yyyy HH:mm' }} </td>
              </ng-container>
          
              <!-- Name Column -->
              <ng-container matColumnDef="clave">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Clave de cliente </th>
                <td mat-cell *matCellDef="let row"> {{row.clave}} </td>
              </ng-container>
          
              <!-- Fruit Column -->
              <ng-container matColumnDef="moneda">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Moneda </th>
                <td mat-cell *matCellDef="let row"> {{row.moneda}} </td>
              </ng-container>
              <!-- Fruit Column -->
              <ng-container matColumnDef="subtotal_ad">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Subtotal antes descuento </th>
                <td mat-cell *matCellDef="let row"> {{row.subtotal_ad | currency }} </td>
              </ng-container>
              <!-- Fruit Column -->
              <ng-container matColumnDef="descuento">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Descuento </th>
                <td mat-cell *matCellDef="let row"> {{row.descuento| currency }} </td>
              </ng-container>
              <!-- Fruit Column -->
              <ng-container matColumnDef="subtotal">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Subtotal </th>
                <td mat-cell *matCellDef="let row"> {{row.subtotal| currency }} </td>
              </ng-container>
              <!-- Fruit Column -->
              <ng-container matColumnDef="iva">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Iva </th>
                <td mat-cell *matCellDef="let row"> {{row.iva| currency }} </td>
              </ng-container>
              <!-- Fruit Column -->
              <ng-container matColumnDef="total">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Total </th>
                <td mat-cell *matCellDef="let row"> {{row.total| currency }} </td>
              </ng-container>
              <!-- Fruit Column -->
              <ng-container matColumnDef="acciones">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Documentos </th>
                <td mat-cell *matCellDef="let row"> 
                    <a [href]="row.xml" target="_blank" mat-icon-button aria-label="expand row" >
                        <mat-icon>receipt
                        </mat-icon>
                    </a>
                    <a [href]="row.pdf" target="_blank" mat-icon-button aria-label="expand row" >
                          <mat-icon>picture_as_pdf</mat-icon>
                    </a>
                </td>
              </ng-container>
          
              <ng-container matColumnDef="seleccion">
                <th mat-header-cell *matHeaderCellDef mat-sort-header></th>
                <td mat-cell class="p-0 m-0" *matCellDef="let row"> 
                    <mat-checkbox></mat-checkbox>
                </td>
              </ng-container>
              <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
          
              <!-- Row shown when there is no matching data. -->
              <tr class="mat-row" *matNoDataRow>
                <td class="mat-cell" colspan="4">No data matching the filter</td>
              </tr>
            </table>
          
            <mat-paginator [pageSizeOptions]="[5, 10, 25, 100]" aria-label="Select page of users"></mat-paginator>
          </div>
    </div>
</div>