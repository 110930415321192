<div class="page-wrapper" id="main-wrapper" data-layout="vertical" data-navbarbg="skin6" data-sidebartype="full" data-sidebar-position="fixed" data-header-position="fixed">    
    <aside class="left-sidebar" [ngClass]="menu_responsive ? 'layout-menu-expanded' : ''">
        <app-sidebar></app-sidebar>
    </aside>    
    <div class="body-wrapper">
        <app-header (menuEvent)="menuEvent($event)"></app-header>
        <div class="container-fluid">
            <router-outlet></router-outlet>
        </div>        
    </div>
</div>
<div class="bg-overlay" [ngClass]="menu_responsive ? 'active' : ''" (click)="menuEvent(false)"></div>