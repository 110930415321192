<header class="app-header">
    <nav class="navbar navbar-expand-lg navbar-light">
        <ul class="navbar-nav">
            <li class="nav-item d-block d-xl-none">
                <a class="nav-link sidebartoggler nav-icon-hover" id="headerCollapse" (click)="menuToggle()">
                    <mat-icon>menu</mat-icon>
                </a>
            </li>
            <li class="nav-item">
                <span class="h4 hideResponsive" (click)="menuToggle()">Hola {{userObj.name}} </span>
            </li>
        </ul>
        <div class="navbar-collapse justify-content-end px-0" id="navbarNav">
            <ul class="navbar-nav flex-row ms-auto align-items-center justify-content-end">
                <li class="nav-item">
                    <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
                        <mat-icon>person</mat-icon>
                    </button>
                    <mat-menu #menu="matMenu">
                        <button mat-menu-item>
                            <mat-icon>group</mat-icon>
                            <span>Usuarios</span>
                        </button>
                        <button mat-menu-item (click)="logOut()">
                            <mat-icon>logout</mat-icon>
                            <span>Cerrar sesión</span>
                        </button>
                    </mat-menu>
                </li>
            </ul>
        </div>
    </nav>
</header>