import { Component } from '@angular/core';

@Component({
  selector: 'app-users-edit-password-firsttime',
  templateUrl: './users-edit-password-firsttime.component.html',
  styleUrls: ['./users-edit-password-firsttime.component.scss']
})
export class UsersEditPasswordFirsttimeComponent {

}
