import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { CustomValidatorsService } from 'src/app/services/custom-validators.service';
import { UsersService } from 'src/app/services/users.service';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-users-add',
  templateUrl: './users-add.component.html',
  styleUrls: ['./users-add.component.scss']
})
export class UsersAddComponent implements OnInit {

  hideEmail = true;
  hideEmailConfirm = true;
  selected : any = [];

  rolesList!: {
    id: number,
    rol: string
  }[];

  formUser!: FormGroup;

  constructor(
    private fb: FormBuilder,
    private customVal: CustomValidatorsService,
    private userS: UsersService,
    private dialogRef: MatDialogRef<UsersAddComponent>
  ) {  }

  ngOnInit(): void {
    this.createFormUser();
    this.rolesList = environment.roles;
  }

  createFormUser() {
    this.formUser = this.fb.group({
      name: ['', [ Validators.required, Validators.minLength(3)]],
      email: ['', [ Validators.required, Validators.pattern("[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$")] ],      
      pass: ['', [Validators.required, Validators.minLength(8)]],
      passConfirm: ['', [Validators.required, Validators.minLength(8)]],
      id_rol: ['', [Validators.required]]
    },{
      validators: this.customVal.samePasswords("pass", "passConfirm")
    });
  }

  unselect(obj : any): void {
    obj = undefined;
 }
  
  ruSure() {
    if(this.formUser.invalid) { return; }
    Swal.fire({
      title: '¿Estás seguro que deseas guardar el registro?',
      icon: 'warning',
      showDenyButton: false,
      showCancelButton: true,
      confirmButtonText: `Confirmar`,
      cancelButtonText: `Cancelar`
    }).then((result) => {      
      if (result.isConfirmed) {
        this.saveUser();
      }
    });
  }

  saveUser() {
    Swal.fire({
      icon: 'info',
      title: 'Guardando...',
      allowEscapeKey: false,
      allowOutsideClick: false,
      showConfirmButton: false,
      didOpen: () => {
        Swal.showLoading();
      }
    });
    this.formUser.value.comision = {tipo: this.formUser.value.tipo_comision, cantidad: this.formUser.value.cant_comision};
    this.userS.saveUser(this.formUser.value).subscribe(
      (result:any) => {
        if(result.message == "Success") {
          Swal.fire({
            icon: 'success',
            title: 'Guardado',
            text: 'Usuario guardado de manera correcta!'          
          }).then(() => {            
            this.dialogRef.close({ reload: false });
          });
        }
      },
      (err:any) => {
        if(err.error.message.indexOf("ER_DUP_ENTRY") > -1)
        {
          Swal.fire({
            icon: 'error',
            title: 'Usuario duplicado',
            text: 'Ya existe un usuario con ese email.'
          });
        }
        else {
          console.log(err);
          Swal.fire({
            icon: 'error',
            title: 'Error al guardar',
            text: 'Ocurrió un error al guardar el registro, por favor vuelve a intentarlo.'
          });
        }
      }
    );
  }

  resetForm() {
    this.selected = [];
    this.formUser.reset();
  }

}
