import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './components/login/login.component';
import { AuthGuard } from './guards/auth.guard';
import { LayoutComponent } from './components/shared/layout/layout.component';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { UsersComponent } from './components/users/users.component';

const routes: Routes = [
  { path: '', pathMatch: 'full', redirectTo: 'login' },
  { path: 'login', component: LoginComponent },
  {
    path: 'admin', canActivate: [AuthGuard], component: LayoutComponent, data: {expectedRoles: ['Any']},
    children: [      
      { path: '', canActivate: [AuthGuard], component: DashboardComponent, data: {expectedRoles: ['Any']} },
      { path: 'dashboard', canActivate: [AuthGuard], component: DashboardComponent, data: {expectedRoles: ['Any']} },      
      { path: 'users', canActivate: [AuthGuard], component: UsersComponent, data: {expectedRoles: ['Any']} }      
    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
