import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MainService {

  private httpConfig: any = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    }),
    body: ""
  };

  private httpConfigForm: any = {
    body: ""
  };

  private apiURL = environment.API_URL;
  constructor(private http: HttpClient) { }

  public postRequest = (model: any, api: string): any => {
    this.httpConfig.body = JSON.stringify(model);
    return this.http.request("POST", this.apiURL + api, this.httpConfig);
  }
  
  public getRequest = (api: string): any => {  
    return this.http.get(this.apiURL + api);
  }

  public getRequest2 = (api: string): Observable<any> => {  
    return this.http.get(this.apiURL + api);
  }


  public deleteRequest = (api: string): any => {
    return this.http.delete(this.apiURL + api);
  }

  public postRequestFormData = (form: FormData, api: string): Observable<any> => {
    this.httpConfigForm.body = form;
    console.log("form sended", this.httpConfigForm.body, form);
    let url = this.apiURL + api;
    return this.http.request("POST", url, this.httpConfigForm);
  }

  private loadingStus: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public getLoadingStatus(): Observable<boolean> {
    return this.loadingStus.asObservable();
  }
  public ShowLoading(){
       this.loadingStus.next(true);
   }

   public HideLoading(){
       this.loadingStus.next(false);
   }

   public cloneObj(obj : any){
    return JSON.parse(JSON.stringify(obj));
   }
}
