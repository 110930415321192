import { Component } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { UserLoginModel } from 'src/app/models/users.model';
import { AuthService } from 'src/app/services/auth.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent {

  user: UserLoginModel = new UserLoginModel();
  hide = true;
  rememberme = false;
  
  constructor(
    private auth: AuthService,
    private router: Router
  ) { }

  ngOnInit(): void {
    if (this.auth.isAuthenticated())
      this.router.navigateByUrl('/admin/dashboard');

    if ( localStorage.getItem('email') ) {
      this.user.email = localStorage.getItem('email')!;
      this.rememberme = true;
    }
  }

  login(form: NgForm) {

    if (form.invalid) { return; }

    this.user.email = this.user.email.trim();

    Swal.fire({
      icon: 'info',
      title: 'Espere...',
      allowEscapeKey: false,
      allowOutsideClick: false,
      showConfirmButton: false,
      didOpen: () => {
        Swal.showLoading();
      }
    });
    
    this.auth.login(this.user).subscribe(resp => {
      if (this.rememberme) {
        localStorage.setItem('email', this.user.email);
      }
      Swal.close();
      this.router.navigateByUrl('admin/dashboard');
    }, (err) => {
      console.log(err.error.message);
      Swal.fire({
        icon: 'error',
        title: 'Error al autenticar',
        text: 'Usuario o contraseña no válido'
      });
    });
  }

  openDialog() {
    Swal.fire({
      icon: 'warning',
      title: '¿Olvidó su contraseña?',
      text: 'Para recuperar su contraseña envié un mensaje solicitando el restablecimiento de su contraseña al correo electrónico "hola@koalo.mx"',
      heightAuto: false
    });
  }

}
