<!-- Sidebar scroll-->
<div>
  <div class="brand-logo d-flex align-items-center justify-content-between">
    <a routerLink="'dashboard" class="text-nowrap logo-img w-100 text-center">
      <img src="../../../../assets/images/logo.png" width="100" alt="" />
    </a>
    <!-- <div class="close-btn d-xl-none d-block sidebartoggler cursor-pointer">
          <mat-icon>close</mat-icon>
        </div> -->
  </div>
  <!-- Sidebar navigation-->
  <nav class="sidebar-nav scroll-sidebar">
    <ul id="sidebarnav">
      <li class="nav-small-cap">
        <span class="hide-menu" class="opacity-50">Inicio</span>
      </li>
      <li class="sidebar-item">
        <a class="sidebar-link" [routerLink]="'dashboard'" [routerLinkActive]="'active'">
          <span>
            <mat-icon>dashboard</mat-icon>
          </span>
          <span class="hide-menu">Dashboard</span>
        </a>
      </li>
      <li class="nav-small-cap opacity-50">
        <span class="hide-menu">Configuración</span>
      </li>
      <li class="sidebar-item">
        <a class="sidebar-link" [routerLink]="'users'" [routerLinkActive]="'active'">
          <span>
            <mat-icon color="accent">group</mat-icon>
          </span>
          <span class="hide-menu">Usuarios</span>
        </a>
      </li>
    </ul>
  </nav>
  <!-- End Sidebar navigation -->
</div>
<!-- End Sidebar scroll-->