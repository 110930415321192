<div class="page-wrapper" id="main-wrapper" data-layout="vertical" data-navbarbg="skin6" data-sidebartype="full"
    data-sidebar-position="fixed" data-header-position="fixed">
    <div
      class="position-relative overflow-hidden radial-gradient min-vh-100 d-flex align-items-center justify-content-center">
      <div class="d-flex align-items-center justify-content-center w-100">
        <div class="row justify-content-center w-100">
          <div class="col-md-8 col-lg-6 col-xxl-3">
            <div class="card mb-0">
              <div class="card-body">
                <a href="/" class="text-nowrap logo-img text-center d-block py-3 w-100">
                  <img src="../assets/images/logo.png" width="150" alt="">
                </a>
                <form (ngSubmit)="login(f)" #f="ngForm" autocomplete="off">
                    <h4>Hola, somos Surtidor Eléctrico 👋</h4>
                    <p>Por favor inicia sesion en tu cuenta</p>
                    <div>
                        <mat-form-field class="w-100" color="primary">
                            <mat-label>Usuario</mat-label>
                            <input matInput placeholder="Usuario" [(ngModel)]="user.email" name="user" required>
                            <mat-icon matSuffix>account_circle</mat-icon>
                        </mat-form-field>
                    </div>
                    <div>
                        <mat-form-field class="w-100" color="primary">
                            <mat-label>Contraseña</mat-label>
                            <input matInput placeholder="Password" [type]="hide ? 'password' : 'text'" [(ngModel)]="user.pass" name="pass" required>
                            <mat-icon matSuffix (click)="hide = !hide" role="button">{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
                        </mat-form-field>
                    </div>
                    <div>
                        <mat-checkbox [(ngModel)]="rememberme" name="rememberme" color="accent">
                            Recordarme
                        </mat-checkbox>
                    </div>
                    <div>
                        <button mat-raised-button color="accent" class="w-100 mt-2" type="submit">Iniciar sesión</button>
                    </div>
                </form>
                <div class="mt-2 text-center">
                    <a role="button" (click)="openDialog()">Olvidé mi contraseña</a>
                </div>                
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>