import { FOCUS_MONITOR_DEFAULT_OPTIONS } from '@angular/cdk/a11y';
import { Component, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ClientDocumentsModel } from 'src/app/models/documents.model';

export interface UserData {
  id: string;
  name: string;
  progress: string;
  fruit: string;
}

const documentos: Array<ClientDocumentsModel> = [{
    id: 1,
    folio: "1313934", 
    fecha: "2023/10/20 12:08",
    clave: "C-101415",
    moneda: "PESOS",
    subtotal_ad: 2857.68,
    descuento: 0,
    subtotal: 2857.68,
    iva: 457.23,
    total: 3314.91,
    xml: "http://186.96.45.187/clientes/comprobantes/xml/202310/F_1313934_20231019.xml",
    pdf: "http://186.96.45.187/clientes/comprobantes/pdf/202310/F_1313934_20231019.pdf"
  },
  {
    id: 2,
    folio: "1313935", 
    fecha: "2023/10/21 12:08",
    clave: "C-101415",
    moneda: "PESOS",
    subtotal_ad: 2857.68,
    descuento: 0,
    subtotal: 2857.68,
    iva: 457.23,
    total: 3314.91,
    xml: "http://186.96.45.187/clientes/comprobantes/xml/202310/F_1313934_20231019.xml",
    pdf: "http://186.96.45.187/clientes/comprobantes/pdf/202310/F_1313934_20231019.pdf"
  },
  {
    id: 3,
    folio: "1313936", 
    fecha: "2023/10/22 12:08",
    clave: "C-101415",
    moneda: "PESOS",
    subtotal_ad: 2857.68,
    descuento: 0,
    subtotal: 2857.68,
    iva: 457.23,
    total: 3314.91,
    xml: "http://186.96.45.187/clientes/comprobantes/xml/202310/F_1313934_20231019.xml",
    pdf: "http://186.96.45.187/clientes/comprobantes/pdf/202310/F_1313934_20231019.pdf"
  },
  {
    id: 4,
    folio: "1313937", 
    fecha: "2023/10/23 12:08",
    clave: "C-101415",
    moneda: "PESOS",
    subtotal_ad: 2857.68,
    descuento: 0,
    subtotal: 2857.68,
    iva: 457.23,
    total: 3314.91,
    xml: "http://186.96.45.187/clientes/comprobantes/xml/202310/F_1313934_20231019.xml",
    pdf: "http://186.96.45.187/clientes/comprobantes/pdf/202310/F_1313934_20231019.pdf"
  },
  {
    id: 5,
    folio: "1313938", 
    fecha: "2023/10/24 12:08",
    clave: "C-101415",
    moneda: "PESOS",
    subtotal_ad: 2857.68,
    descuento: 0,
    subtotal: 2857.68,
    iva: 457.23,
    total: 3314.91,
    xml: "http://186.96.45.187/clientes/comprobantes/xml/202310/F_1313934_20231019.xml",
    pdf: "http://186.96.45.187/clientes/comprobantes/pdf/202310/F_1313934_20231019.pdf"
  }
];

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent {
  displayedColumns: string[] = ['folio', 'fecha', 'clave', 'moneda', 'subtotal_ad', 'descuento', 'subtotal', 'iva', 'total', 'acciones', 'seleccion'];
  dataSource!: MatTableDataSource<ClientDocumentsModel>;

  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;  

  constructor() {
    this.dataSource = new MatTableDataSource(documentos);
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }
}