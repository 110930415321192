import { Component } from '@angular/core';

@Component({
  selector: 'app-users-edit-password',
  templateUrl: './users-edit-password.component.html',
  styleUrls: ['./users-edit-password.component.scss']
})
export class UsersEditPasswordComponent {

}
